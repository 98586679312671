var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-container',[_c('v-row',{class:_vm.fullWidth ? 'pt-5' : 'pt-15',attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.fullWidth ? 12 : 10}},[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.tableTitle)+" "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Buscar","clearable":"","single-line":"","hide-details":"","disabled":_vm.disableSearch},on:{"input":function($event){return _vm.setSearch(_vm.search)},"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(!_vm.showInactiveItems)?_c('v-btn',{staticClass:"mt-3",attrs:{"tile":"","color":"primary","to":{ name: 'Nuevo Materias Profesor' },"disabled":_vm.disableBtnCreate}},[_vm._v(" + "+_vm._s(_vm.newButtonLabel)+" ")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando... Espera por favor","no-data-text":"Aún no hay registros","no-results-text":"No se encontraron coincidencias","hide-default-footer":_vm.hideDefaultFooter,"footer-props":{
              itemsPerPageText: 'Elementos por página',
            }},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.changeGroupForAll(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-group")])]}}],null,true)},[_c('span',[_vm._v("Mover todos los estudiantes a otro grupo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.moveGroupAToGroupB(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-swap-horizontal-bold")])]}}],null,true)},[_c('span',[_vm._v("Mover estudiantes a otro grupo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.addStudentToGroup(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-multiple-plus")])]}}],null,true)},[_c('span',[_vm._v("Agregar estudiante al grupo")])])],1)]}},{key:`item.avatar`,fn:function({ item }){return [(
                  item.imagen_grupo &&
                  item.imagen_grupo.imagen &&
                  item.imagen_grupo.imagen.includes('digitaloceanspaces.com')
                )?_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":item.imagen_grupo.imagen,"width":"30","onError":"handleError('Error loading image')"}})],1):(item.imagen_grupo && item.imagen_grupo.imagen)?_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":item.imagen_grupo.imagen,"width":"30","onError":"handleError('Error loading image')"}})],1):_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":require(`../../../../assets/images/huellita.png`),"width":"30"}})],1)]}}],null,true)})],1)],1)],1),_c('v-row',{class:`pt-0 ${_vm.hideDefaultFooter ? '' : 'mt-n16'}`,attrs:{"justify":"center"}},[(_vm.showBottomAction)?_c('v-col',{attrs:{"cols":`${_vm.hideDefaultFooter ? 12 : 10}`,"md":`${_vm.hideDefaultFooter ? 5 : 10}`,"lg":`${_vm.hideDefaultFooter ? 5 : 10}`,"xl":`${_vm.hideDefaultFooter ? 5 : 10}`}},[_c('div',{staticClass:"d-flex justify-center justify-xs-center justify-sm-center justify-md-start justify-lg-start- justify-xl-start"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.emitShowAction}},[_c('span',{staticClass:"text-capitalize mr-2"},[_vm._v(" "+_vm._s(_vm.bottomText)+" ")])])],1)]):_vm._e(),(_vm.hideDefaultFooter)?_c('v-col',{attrs:{"cols":`${_vm.showBottomAction ? 12 : 10}`,"md":`${_vm.showBottomAction ? 5 : 10}`,"lg":`${_vm.showBottomAction ? 5 : 10}`,"xl":`${_vm.showBottomAction ? 5 : 10}`}},[(_vm.paginationCount)?_c('v-pagination',{staticClass:"float-md-right float-lg-right float-xl-right",attrs:{"total-visible":"10","length":_vm.paginationCount},on:{"input":function($event){return _vm.emitChangePage()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }