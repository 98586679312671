import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import KnMenu from '../../../shared/components/KnMenu.vue';
// import { can } from '../../../shared/helpers/permissionsUtils';
import { Actions } from '../../../shared/helpers/permissionContants';

export default {
  components: { KnMenu },
  mixins: [paginationMixin],
  props: {
    tableTitle: {
      type: String,
      default: '',
    },
    rememberLastPage: {
      type: Boolean,
      default: false,
    },
    newButtonLabel: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disableBtnCreate: {
      type: Boolean,
      default: false,
    },
    showBottomAction: {
      type: Boolean,
      default: false,
    },
    showInactiveItems: {
      type: Boolean,
      default: false,
    },
    bottomActionText: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paginationCount: {
      type: Number,
      default: 0,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      search: null,
    };
  },
  computed: {
    ...mapState(['currentPage']),
    ...mapGetters(['tutorHasDebts', 'hasAdminPermission']),
    bottomText() {
      return this.bottomActionText ? this.bottomActionText : `Ver ${this.tableTitle} inactivos`;
    },
    section() {
      return this.newButtonLabel.includes('alumno') ? 'Alumno' : '';
    },
    routeName() {
      return this.newButtonLabel.includes('alumno') ? 'Nuevo Alumno' : '';
    },
  },
  watch: {
    paginationCount() {
      if (this.rememberLastPage) {
        this.page = this.currentPage || 1;
        this.setCurrentPage(this.currentPage);
      } else {
        this.page = 1;
      }
    },
  },
  methods: {
    ...mapMutations(['setSearch', 'setSelectedId', 'setCurrentPage']),
    ...mapActions(['clearSearch']),
    
    menuOptions(item) {
      return [
        {
          title: 'Editar',
          action: () => this.goTo(`Editar Materia`, { entity: item }),
          section: ['Materia'],
          permissions: [Actions.change],
          active: true,
          disabled: false,
        },
        {
          title: 'Desactivar',
          action: () => this.emitAction2(item.id),
          section: ['Materia'],
          permissions: [Actions.delete],
          active: true,
          disabled: false,
        },
        {
          title: 'Reactivar',
          action: () => this.emitAction2(item.id),
          section: ['Materia'],
          permissions: [Actions.change],
          active: false,
          disabled: false,
        },
      ]
        // .filter(option => option.section.some(s => s === this.section) && option.active === !this.showInactiveItems)
        // .map(option => {
        //   option.permissions && option.permissions.length
        //     ? (option.disabled = !option.permissions.some(p => can({ actions: [p], resource: this.section })))
        //     : (option.disabled = false);
        //   return option;
        // });
    },
    goTo(routeName, params) {
      this.setSelectedId(params.entity.id);
      return this.$router.push({ name: routeName, params: { id: params.entity.id } });
    },
    emitAction(value) {
      this.$emit('action', value);
    },
    emitAction2(value) {
      this.$emit('action2', value);
    },
    emitShowAction() {
      this.clearSearch();
      this.$emit('showAction');
    },
    changeGroupForAll(value){
      this.$emit('action3', value);
    },
    moveGroupAToGroupB(item){
      this.setSelectedId(item.id);
      return this.$router.push({ name: 'Mover Alumnos', params: { id: item.id } });
    },

    addStudentToGroup(item){
      this.setSelectedId(item.id);
      return this.$router.push({ name: 'Administrar Alumnos', params: { id: item.id } });
    },
    removeStudentFromGroup(item){
      this.setSelectedId(item.id);
      return this.$router.push({ name: 'Administrar Alumnos', params: { id: item.id } });
    },
    emitChangePage() {
      this.$emit('onChangePage', this.page);
    },
  },
};
