
// export default {
//   name: 'GroupActionsIndex',
//   components: {
//     KnTableGroupActions
//   },
//   mixins: [],
//   props: {},
//   data() {
//     return {};
//   },
//   computed: {},
//   created() { },
//   methods: {},
// };
import { mapState } from 'vuex';

import { canAdd, canDelete, insufficientPermissionsMessage } from "../../../shared/helpers/permissionsUtils";
import { fetchRemoveSubject } from '../../helpers/subjectsOptions';
import { getItem } from "../../../../api/api-methods";
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import KnTableGroupActions from "../../components/KnTableGroupActions/KnTableGroupActions.vue";
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnModalChangeGroupAll from '../../components/KnModalChangeGroupAll/KnModalChangeGroupAll.vue';

export default {
  name: 'GroupActionsIndex',
  components: {
    KnLocalAlert,
    KnTableGroupActions,
    KnModalChangeGroupAll
  },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      headers: [
        { text: '', value: 'avatar' },
        { text: 'Nombre', value: 'nombre_grupo', class: 'purple--text' },
        { text: 'Salon', value: 'salon_clases.nombre_salon', class: 'purple--text' },
        { text: 'Acciones', value: 'actions', sortable: false, class: 'purple--text' },
      ],
      items: [],
      loading: false,
      showInactive: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */

      // Modal
      showGroupModal: false,
      currentGroup: {}, // Add the current group data here
      selectedGroup: null,
      groups: [], // Add the list of groups here
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search', 'currentPage']),
  },
  async created() {
    await this.getGroups(true)
  },
  watch: {
    search: {
      async handler() {
        if (this.loading) return;
        await this.getSubjects(!this.showInactive);
      },
    },
  },
  methods: {
    canAdd: canAdd,
    canDelete: canDelete,
    tableTitle() {
      return this.showInactive ? 'Grupos inactivos' : 'Grupos';
    },
    
    buttomActionText() {
      return this.showInactive
        ? 'Ver grupos activos'
        : 'Ver grupos inactivos'
    },
    closeModal(){
      this.showGroupModal = false
    },
    
    async getGroups(visible = true) {
      this.loading = true;
      try {
        const limitFilter = this.pagination.limit !== null ? `&limit=${this.pagination.limit}` : '';
        const offsetFilter = this.pagination.offset !== 0 ? `&offset=${this.pagination.offset}` : '';
        const searchFilter = this.search !== null ? `&nombre_grupo=${this.search}` : '';

        const responseData = await getItem(`/app-personas/filters/grupo?institucion_educativa=${this.institutionId}&estatus_sistema=${visible}&${limitFilter}${offsetFilter}${searchFilter}`)
        this.setPaginationCount(responseData.count);
        this.items = responseData.results

      } catch (error) {
        console.error('Error al obtener grupos:', error);
      } finally {
        this.loading = false;
      }
    },

    async action2(value) {
      console.log('activar o reactiva', value);
      const { ok } = await fetchRemoveSubject(value)
      if (ok)
        await this.getGroups(!this.showInactive)
    },
    async action3(value) {
      console.log('Mover todos', value);
      this.showGroupModal = true
      this.currentGroup = value
    },
    confirmGroupChange() {
      this.showGroupModal = false
    },
    async changePageSubjects(page) {
      this.setPaginationPage(page);
      await this.getGroups()
    },
    async actionInactive(value) {
      await this.getGroups(!value)
      this.showInactive = value;
    }
  },
};
